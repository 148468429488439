import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import SearchBox from '../SearchBox'
import { ReactComponent as Logo } from '../../../static/img/logo.svg'

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <nav className="flex justify-between items-center bg-white px-4 md:px-6 py-0 mt-4 md:py-2 lg:mt-0" aria-label='main navigation'>

        <div className="md:block hidden">
          <div className="text-sm">
            <Link className='block inline-block text-black-200 font-medium hover:underline mr-6' to='/docs'>
              Documentation
            </Link>
            <Link className='block inline-block text-black-200 font-medium hover:underline mr-6' to='/blog'>
              Blog
            </Link>
            <Link className='block inline-block text-black-200 font-medium hover:underline' to='/pricing'>
              Pricing
            </Link>
          </div>
        </div>

        <div className="text-black inline-block">
          <Link to='/' className=''>
            <Logo />
          </Link>
        </div>

        <div className="text-black">
          <div>
            <Link
              className='inline-block text-sm mr-2 px-2 py-2 md:mr-4 md:px-4 md:py-2 leading-none border text-indigo-500 border-indigo-500 hover:border-transparent hover:text-indigo-500 hover:bg-white font-medium'
              to='/omhdemo'>
                      <span className="hidden sm:inline-block">Live&nbsp;</span>Demo
            </Link>
            <Link
              className='inline-block text-sm px-2 py-2 md:px-4 md:py-2 leading-none border border-indigo-500 text-white bg-indigo-500 hover:border-transparent hover:text-indigo-500 hover:bg-white font-medium'
              to='/omh'>
                      Start Trading
            </Link>
          </div>
        </div>

      </nav>
    )
	}
  />
)

export default NavBar
