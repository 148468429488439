import React from 'react'
import config from '../../../config'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <div className="bg-white flex">
      <div className="container mx-auto px-8">

        <footer className="flex text-xs text-gray-500 h-12 items-center">
          <div className="flex-shrink text-left">
            {config.copyright}
          </div>
          <div className="flex-shrink-0 flex-grow text-right">
            <Link className='block inline-block hover:underline mr-6' to='/pricing'>
              Privacy Policy
            </Link>
            <Link className='block inline-block hover:underline' to='/blog'>
              Terms of Service
            </Link>
          </div>
        </footer>

      </div>
    </div>
  )
}

export default Footer
