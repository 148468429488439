module.exports = {
  siteTitle: 'meowwareOMH - Order Management for Humans', // Site title.
  siteTitleAlt: 'meowwareOMH', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.meowware.xyz', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Become a trading superhuman. Offload emotion and memory overhead to robots.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: 'GTM-NM7LRC2', // GTM tracking ID.
  userName: '',
  userTwitter: 'meowware',
  userLocation: '',
  userDescription: '',
  copyright: '© 2020 Blep, LLC. All rights reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
